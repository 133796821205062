import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import "./Button.css";

const FileUploadButton = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const didMount = useRef(false);
  const hiddenFileInput = useRef(null);

  const onChangeHandler = (event) => {
    console.log(event.target.files[0]);
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    if (didMount.current === false) didMount.current = true;
    if (didMount.current) {
      if (selectedFile != null) {
        props.onFileChange(
          (props.questionNum + 1).toString(),
          selectedFile,
          selectedFile.name
        );
      }
    }
  }, [props, selectedFile]);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  return (
    <div>
      <button for="file" onClick={handleClick} className="button-small">
        Upload Files
      </button>
      <input
        type="file"
        name="file"
        accept="image/png, image/jpeg, .xlsx, .xls, .csv, .pdf, .docx, .doc"
        style={{ display: "none" }}
        ref={hiddenFileInput}
        multiple
        onChange={onChangeHandler}
      />
    </div>
  );
};

export default FileUploadButton;
