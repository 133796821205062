import "./Questions.css";
import SubmitButton from "./SubmitButton";
import Radio from "./Radio";
import FileUploadButton from "./FileUploadButton";
import logo from "../assets/Halderman-Logo.png";
import { Link } from "react-router-dom";

const Questions = (props) => {
  if (props.items.length === 0) {
    return <h2 className="question-error">ERROR: No Questions.</h2>;
  }

  const submitHandler = (event) => {
    event.preventDefault();
    props.onSubmitClick();
  };

  function changeHandler(event) {
    let update = [...props.items];
    update[this].value = event.target.value;
    props.onChangeQuestions(update);
  }

  function createForm() {
    return props.items.map((question, i) => {
      return (
        <div className="question" key={i}>
          <h2>{question.title}</h2>

          <div className="question-desc">{question.desc}</div>

          {question.type === "checkboxes" && (
            <ul>
              {question.bullets.map((bullet) => (
                <div>
                  <li> {bullet.name} </li>
                  <Radio bullet={bullet} />
                </div>
              ))}
            </ul>
          )}

          <div className="row">
            <div className="float-child">
              <FileUploadButton onFileChange={props.onFileChange} questionNum={i}/>
            </div>
            <div className="float-child">
                <button type="text" className="button-small">   
                <Link to={`/guidance?id=${question.id}`} className="link-q" target="_blank" rel="noopener noreferrer">
                  Guidance
                </Link>
                </button>
            </div>
          </div>

          <textarea className='input-text'
                            placeholder="Provide more information."
                            value={question.value}
                            onChange={changeHandler.bind(i)}
                            rows={5} />
        </div>
      );
    });
  }

  return (
    <form className="questions-list" onSubmit={submitHandler}>
      <div className="header">
        <img className="logo" src={logo} alt="Halderman Logo" />
        <h1 className="header-h1">| Eco Elite Form</h1>
      </div>
      <div className="introduction">
        <h3 className="header-h3">Instructions:</h3>
        <p className="header-p">
          Please answer all of the questions below to the best of your ability.
          For each question, you will have the option to type an answer and
          attach any files using the "Upload Files" button. If you need help or
          assistance with answering a question, you can click the "Guidance"
          button, which will open a page that contains information about the
          question to help you with your answer. <b>If you are re-submitting the form with
          updates:</b> Please only answer the questions for which your prior response has changed.
          <br></br> <br></br>
          About the form: Halderman Farm Management Service, Inc. (HFMS)
          encourages our landowners to be good stewards of the resources given
          to us. Understanding that our client’s current ownership is part of
          the long term life of a farm and that our client’s want the farm to be
          better for future generations of owners, HFMS developed a process
          whereby a managed property can become Halderman Eco-Elite. The goal is
          to manage our farms in a sustainable way to enhance the long-term
          viability of the farm and operations. This program takes an ESG
          (Environmental, Sustainable, Governance) approach to protecting and
          enhancing the resources on the farm and the surrounding areas, the
          people engaged in managing and operating the farm and the governance
          of the farm management.
        </p>
      </div>
      {createForm()}
      <SubmitButton type="submit" message="Submit Form" />
      <button type="text" className="button">   
                <Link to={`/FarmManager`} className="link-q">
                  Cancel
                </Link>
      </button>
    </form>
  );
};

export default Questions;
