import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import Form from "./pages/Form";
import About from "./pages/About";
import NoPage from "./pages/NoPage";
import FarmManager from "./pages/FarmManager";
import Guidance from "./pages/Guidance";
import PostLayout from "./pages/PostLayout";

export default function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />} >
          <Route index element={<Login />} />
          <Route path="SignUp" element={<SignUp />}  />
          <Route path="about" element={<About />}  />
          <Route path="contact" component={() => { 
            window.location.href = 'https://www.halderman.com/contact/'; 
            return null;
          }}/>
        </Route>
        <Route path="/" element={<PostLayout />} >
          <Route path="form" element={<Form />} />
          <Route path="*" element={<NoPage />} />
          <Route path="FarmManager" element={<FarmManager />} />
          <Route path="About" element={<About />} />
        </Route>
          <Route path="guidance" element={<Guidance />} />
      </Routes>
    </BrowserRouter>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));