import './About.css';
import { Outlet } from 'react-router-dom';

function About() {

  return (
    <div className="About">
      <h1> About Eco-Elite </h1>
      <p className='p-text'>Halderman Real Estate created the Eco Elite designation in coordination with industry, farmer, area manager feedback, as well as collaboration with Purdue University.
        The objective of this project is to enable farms of varying sizes to work towards being more sustainaible by providing both a clear set of guidelines and a designation
        that allows farms to adversite their Eco-Elite status to customers and producers.</p>
       <p className='p-text'>We believe that the rapidly growing demand for sustainable food will drive the argicultural market of the future. Climate change requires us to grow food in a 
           way that protects the planet, and customers will begin to and continue to seek out food that meets that standard more and more. As the agricultural industry rapidly changes, it is important
           that farmers can stay sustainable. Providing farmers with the tools to recognize and certify that they are apart of this movement is an essential piece towards this evolution.</p>
      <div className='button-wrapper'>
        <a href='https://www.halderman.com/'  target="_blank" rel="noopener noreferrer" className='learn-more-button'>Learn More About Halderman <i class="fas fa-angle-right" /></a>
      </div>
      <Outlet />
    </div> 
  )
}
  
  export default About;
