import './FarmStatus.css';

const FarmStatus = (props) => {
    const options = [
        {label: 'Unsubmitted Farms', value: 0},
        {label: 'Submitted Farms', value: 0},
    ];

    for (const farm of props.farms) {
        const farmStatus = farm.status;
        if (farmStatus === "Unsubmitted") {
            options[0].value += 1;
        } else if (farmStatus === "Submitted") {
            options[1].value += 1;
        }
    }

    const totalSum = options.reduce((accumulator, point) => {
        return accumulator + point.value;
      }, 0);

    return (
        <div className='status'>
            <div className='status-bar'>
                <div className='status-bar-label'>
                    Percent of Farms Unsubmitted ({Math.round((options[0].value / totalSum) * 100) + '%'}) 
                </div>
                <div className='status-bar-inner'>
                    <div className='status-bar-fill' style={{width: Math.round((options[0].value / totalSum) * 100) + '%'}} />
                </div>
                
            </div>
            <div className='status-bar'>
                <div className='status-bar-label'>
                    Percent of Farms Submitted ({Math.round((options[1].value / totalSum) * 100) + '%'})
                </div>
                <div className='status-bar-inner'>
                    <div className='status-bar-fill' style={{width: Math.round((options[1].value / totalSum) * 100) + '%'}} />
                </div>
            </div>
        </div>
    );
};

export default FarmStatus;