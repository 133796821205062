import React from 'react';
import './Radio.css';

const Radio = props => {

    const onChangeValue = (event) => {
        props.bullet.response = event.target.value;
    }

    return (
        <div className='radio' onChange={onChangeValue}>
            <div className='entry'>
                <input type="radio" name={props.bullet.name} value="Y" />
                <label className='label'>Yes</label>
            </div>
            <div className='entry'>
                <input type="radio" name={props.bullet.name} value="N" />
                <label className='label'>No</label>
            </div>
            <div className='entry'>
                <input type="radio" name={props.bullet.name} value="X" />
                <label className='label'>Not Applicable</label>
            </div>
        </div>
    )
}

export default Radio;
