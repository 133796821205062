import React, { Component } from 'react';

import GoogleMapReact from 'google-map-react';
//import Maps from 'google-map-react';

import Autocomplete from './Autocomplete';
import MapMarker from './MapMarker';
import './Map.css';
class Map extends Component {

    state = {
        mapApiLoaded: false,
        mapInstance: null,
        mapApi: null,
        geoCoder: null,
        places: [],
        center: [],
        zoom: 9,
        address: '',
        draggable: true,
        lat: null,
        lng: null
    };

    componentWillMount() {
        this.setCurrentLocation();
    }

    onMarkerInteraction = (childKey, childProps, mouse) => {
        this.setState({
            draggable: false,
            lat: mouse.lat,
            lng: mouse.lng
        });
        this.props.updateCor(mouse.lat, mouse.lng);
    }
    onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
        this.setState({ draggable: true });
        this._generateAddress();
    }

    _onChange = ({ center, zoom }) => {
        this.setState({
            center: center,
            zoom: zoom,
        });

    }

    _onClick = (value) => {
        this.setState({
            lat: value.lat,
            lng: value.lng
        });
        this.props.updateCor(value.lat, value.lng);
        this._generateAddress();
    }

    apiHasLoaded = (map, maps) => {
        this.setState({
            mapApiLoaded: true,
            mapInstance: map,
            mapApi: maps,
        });

        this._generateAddress();
    };

    addPlace = (place) => {
        this.setState({
            places: [place],
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
        });
        this.props.updateCor(place.geometry.location.lat(), place.geometry.location.lng());
        this._generateAddress();
    };

    _generateAddress() {
        const {
            mapApi
        } = this.state;

        const geocoder = new mapApi.Geocoder();

        geocoder.geocode({ 'location': { lat: this.state.lat, lng: this.state.lng } }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    this.zoom = 12;
                    this.setState({ address: results[0].formatted_address });
                    this.props.updateAddr(results[0].formatted_address);
                } else {
                    window.alert('No results found');
                }
            }

        });
    }

    // Get Current Location Coordinates
    setCurrentLocation() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState({
                    center: [position.coords.latitude, position.coords.longitude],
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                });
                this.props.updateCor(position.coords.latitude, position.coords.longitude);
            });
        }
    }

    getMapOptions(maps) {
       return { 
        mapTypeControl: true,
        streetViewControl: false,
        scaleControl: true,
        fullscreenControl: false,
        keyboardShortcuts: false,
        mapTypeId: maps.MapTypeId.HYBRID,
        mapTypeControlOptions: {
            style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: maps.ControlPosition.BOTTOM_CENTER,
            mapTypeIds: [
                maps.MapTypeId.ROADMAP,
                maps.MapTypeId.HYBRID
            ]
        }
    };
    }

    render() {
        const {
            mapApiLoaded, mapInstance, mapApi,
        } = this.state;


        return (
            <div className='main-wrapper'>
            <div className='wrapper'>
                {mapApiLoaded && (
                    <div>
                        <Autocomplete map={mapInstance} mapApi={mapApi} addplace={this.addPlace} />
                    </div>
                )}
                <GoogleMapReact
                    center={this.state.center}
                    zoom={this.state.zoom}
                    draggable={this.state.draggable}
                    onChange={this._onChange}
                    onChildMouseDown={this.onMarkerInteraction}
                    onChildMouseUp={this.onMarkerInteractionMouseUp}
                    onChildMouseMove={this.onMarkerInteraction}
                    onChildClick={() => console.log('child click')}
                    onClick={this._onClick}
                    options={this.getMapOptions}
                    bootstrapURLKeys={{
                        key: '<INSERT_API_KEY_HERE>',
                        libraries: ['places', 'geometry'],
                    }}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}
                >

                    <MapMarker
                        text={this.state.address}
                        lat={this.state.lat}
                        lng={this.state.lng}
                    />
                </GoogleMapReact>
            </div >
            </div>
        );
    }
}

export default Map;
