import './Guidance.css';
import * as  React from 'react';
import { useLocation } from "react-router-dom";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

const Guidance= () => {
    const { search } = useLocation();

    let query = React.useMemo(() => new URLSearchParams(search), [search]);
    const [value, setValue] = React.useState(query.get("id") - 1);

    const handleChange = (event, newValue) => {
         setValue(newValue);
     };

    return (
        <>
        <div className='guidance-wrapper'>
            <h1>Question Guidance</h1>
            <p>The following tabs provide additional information about what each question is looking for, links to helpful 
                external resources, and rubrics (when applicable) of what pratices qualify to earn credit for the corresponding
                directives. Click between tabs to see the guidance for the corresponding question number. When you are done, just
                close this tab and continue filling out the form.
            </p>
        </div>
        <div className='guidance-wrapper guidance-tabs'>
    <Box
    sx={{ flexGrow: 1, bgcolor: 'background.white', display: 'flex', height: 490}} className='guidance-tags'>
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={value}
      onChange={handleChange}
      aria-label="Guidance for Form Questions"
      sx={{ borderRight: 1, borderColor: 'divider' }}
    >
        <Tab label="Question 1" {...a11yProps(0)} />
        <Tab label="Question 2" {...a11yProps(1)} />
        <Tab label="Question 3" {...a11yProps(2)} />
        <Tab label="Question 4" {...a11yProps(3)} />
        <Tab label="Question 5" {...a11yProps(4)} />
        <Tab label="Question 6" {...a11yProps(5)} />
        <Tab label="Question 7" {...a11yProps(6)} />
        <Tab label="Question 8" {...a11yProps(7)} />
        <Tab label="Question 9" {...a11yProps(8)} />
        <Tab label="Question 10" {...a11yProps(9)} />
        <Tab label="Question 11" {...a11yProps(10)} />
        <Tab label="Question 12" {...a11yProps(11)} />
        <Tab label="Question 13" {...a11yProps(12)} />
        <Tab label="Question 14" {...a11yProps(13)} />
        <Tab label="Question 15" {...a11yProps(14)} />
        <Tab label="Question 16" {...a11yProps(15)} />
        <Tab label="Question 17" {...a11yProps(16)} />
        <Tab label="Question 18" {...a11yProps(17)} />
        <Tab label="Question 19" {...a11yProps(18)} />
        <Tab label="Question 20" {...a11yProps(19)} />
        <Tab label="Question 21" {...a11yProps(20)} />
    </Tabs>
    <TabPanel className='guidance-panel' value={value} index={0}>
    <h2> Question 1 Guidance</h2>
    <h3>
        <em>This question supports Objective 11 from the Leading Harvest Sustainable Farming Standards. This objective focuses on Legal Compliance with federal laws and regulations.</em></h3>
      <p>Relevant definitions to aid in answering this question are below: </p>
      <div>
        <li><b>Fair Labor Standards Act</b> - Establishes minimum wage, overtime pay (over 40 hours), and 
        child labor protection. </li>

        <li><b>Federal Equal Employment Opportunity Law</b> - Prohibits discriminating against an employee or
        applicant based on their race, sex, religion, disability, national origin, and age. </li>

        <li><b>Migrant and Seasonal Agricultural Worker Protection Act</b> - Protects agricultural workers by establishing
        standards regarding wages, transportation, housing, disclosure, and recordkeeping. 

        (<a href="https://www.dol.gov/sites/dolgov/files/WHD/legacy/files/whdfs49.pdf" target="_blank" rel="noreferrer">https://www.dol.gov/sites/dolgov/files/WHD/legacy/files/whdfs49.pdf</a>)</li>

        <li><b>Abolition of Forced Labor Act</b> - Prohibits any form of forced or compulsory labor. </li>

        <li><b>Victims of Trafficking and Violence Protection Act</b> - Benefits and protection for victims of 
        trafficking. This includes immigrants. </li>

        <li><b>Occupational Health and Safety Act (OSHA)</b></li>

        <p>OSHA provides ability to: 	Request OSHA to inspect workplace. Employees may use 
          rights under law without retaliation and discrimination. Employees receive training about hazards,
          methods to prevent harm, and the OSHA standards that apply to their workplace. The training 
          should be in a language employees understand. Employees can be terminated for noncompliance
          with safety regulations and employers are at risk when employees don’t follow OSHA regulations.  </p>

      <p>Scoring guidelines for this question are as follows: </p>
        </div>
      <table>
        <tr>
          <th>Unsatisfactory</th>
          <th>Satisfactory/Full Points</th>
        </tr>
        <tr>
          <td>No document uploaded, no acceptable explanation, wrong document uploaded. </td>
          <td>User provides ample documentation and thoroughly explains, meets all criteria. </td>
        </tr>
        <tr>
          <td>Does not comply with current regulations and makes no plans to improve. </td>
          <td>Complies with current regulations. </td>
        </tr>
      </table>
    </TabPanel>
    <TabPanel className='guidance-panel' value={value} index={1}>
    <h2>Question 2 Guidance</h2>
      <h3><em>This question supports Objective 11, 7, 10.3, 4, and 3.2 from the Leading Harvest Sustainable Farming Standards. 
      These objectives focus on Legal Compliance (11), Conservation of Biodiversity (7), Sufficient Employee Training (10.3),
      Integrated Pest Management Program for Crop Protection (4), and Water Quality (3.2).</em></h3>
        <div>
      <p>Relevant definitions to aid in answering this question are below: </p>

        <li><b>Clean Air Act</b> – Federal air quality law ensuring acceptable air and controlling pollution across the
        country. </li>

        <p>Producers are in compliance with the Clean Air Act and its amendments to protect and enhance air resources
          to promote public health and welfare</p>

        <li><b>Federal Migratory Bird Treaty</b> – prohibits the taking of birds and bird parts, or to kill birds without
        permission from the Secretary of the Interior. Also prohibits them from using bait or hunting in areas they know 
        are baited.  </li>

        <li><b>Safe Water Drinking Act</b> - This was established to protect the quality of drinking water in the U.S. It 
        focuses on all waters actually or potentially designed for drinking use, whether from above ground or underground sources. </li>

        <li><b>Resource Conservation and Recovery Act</b> - This act gives the EPA authority to control hazardous waste from 
        cradle to grave. </li>
        </div>
        <p>Scoring guidelines for this question are as follows: </p>
      <table>
        <tr>
          <th>Unsatisfactory</th>
          <th>Satisfactory/Full Points</th>
        </tr>
        <tr>
          <td>No document uploaded, no acceptable explanation, wrong document uploaded. </td>
          <td>User provides ample documentation and thoroughly explains, meets all criteria. </td>
        </tr>
        <tr>
          <td>Does not comply with current regulations and makes no plans to improve. </td>
          <td>Complies with current regulations. </td>
        </tr>
      </table>
    </TabPanel>
    <TabPanel className='guidance-panel' value={value} index={2}>
    <h2>Question 3 Guidance</h2>
      <h3><em>This question supports Objective 2.2 from the Leading Harvest Sustainable Farming Standards. This objective 
        focuses on Soil Conservation.</em></h3>
        <div>
        <p>Producers should not plant on a converted wetland unless converted through FSA legally. Producers
          should be in compliance with Section 404 of Clean Water Act regarding agricultural impacts on wetlands. 
          Producers should also be in compliance with U.S. Wetlands Conservation Provisions. USDA will keep record of
          wetland determinations, so aerial imagery may be obtained of the farms and a printout of the farm and tract
          records from the local USDA office administering the farm may be used. Producers are expected to maintain
          compliance with wetland conservation regulations by creating a required conservation system plan. Do not
          convert a wetland to make possible production of an agricultural commodity. A wetland is defined as an area
          that has a predominance of hydric soils; is inundated or saturated by surface or groundwater at a frequency
          and duration sufficient to support a prevalence of water tolerant vegetation typically adapted for life in
          saturated soil conditions.</p>
        
        <p>Producers should be in compliance with U.S. laws regarding conversion of primary forests to other uses.
           and U.S. laws prohibiting the conversion of public lands in National Forests and Grasslands.</p>
        
        <p>Producers should be in compliance with applicable state laws that prohibit changing peatland in any way 
          without a regulated permit.</p>

        <p>Producers are in compliance with U.S. laws that prohibit the production of Crops on land under federal 
          protected status, land designated Wilderness or Research Natural Areas, protected land in National Forests 
          and Grasslands, and land in the National Landscape Conservation System. Producers are in compliance with 
          U.S. laws that prohibit production of Crops on land protected by National Park Service. </p>
        
        <p>Producers are in compliance with U.S. laws that prohibit altering the habitat where endangered or 
          threatened species are found in such a way that disrupts essential behavioral patterns including but not 
          limited to: breeding, feeding, sheltering.</p>

        <p>Producers are in compliance with U.S. Endangered Species Act to protect listed animal and plant species 
          from extinction by preserving the ecosystems in which they survive.</p>

        <p>A Habitat Conservation Plan is required part of an application to private entities undertaking projects 
          that might result in the destruction of an endangered or threatened species.</p>
        
        <p>Producers are in compliance with Highly Erodible Land Conservation program.Producers will maintain
           compliance with highly erodible land regulations by creating a required conservation system plan.
           Highly erodible land is defined as soils that have an erodibility index of eight or more.  </p>
      </div>
    </TabPanel>
    <TabPanel className='guidance-panel' value={value} index={3}>
    <h2>Question 4 Guidance</h2>
    <h3><em>This question supports Objective 2.2 from the Leading Harvest Sustainable Farming Standards. This objective 
      focuses on Soil Conservation.</em> </h3>
      <p>Relevant definitions to aid in answering this question are below: </p>
      <div>
        <li><b>Conservation Reserve Program</b> - Government pays farmers to cease agriculture work on environmentally 
        sensitive land and plant crops that can help improve the environment. Contracts are yearly rental payments and 
        last 10 to 15 years.
        <a href="https://www.fsa.usda.gov/programs-and-services/conservation-programs/conservation-reserve-program/" target="_blank" rel="noreferrer">https://www.fsa.usda.gov/programs-and-services/conservation-programs/conservation-reserve-program/</a></li>

        <li><b>Conservation Stewardship Program</b> - Helps farmers identify problems in their operation and address
        issues such as soil loss and excess water. 
        <a href="https://www.nrcs.usda.gov/wps/portal/nrcs/main/national/programs/financial/csp/" target="_blank" rel="noreferrer">https://www.nrcs.usda.gov/wps/portal/nrcs/main/national/programs/financial/csp/</a></li>

        <li><b>Environmental Quality Incentive Program</b> - Provide financial and technical assistance to deliver environmental benefits like improved water and air quality.
        <a href="https://www.nrcs.usda.gov/wps/portal/nrcs/main/national/programs/financial/eqip/" target="_blank" rel="noreferrer">https://www.nrcs.usda.gov/wps/portal/nrcs/main/national/programs/financial/eqip/</a></li>
      
        <li><b>Regional Conservation Partnership Program</b> - Helps farmers address natural resource concerns and challenges.
        <a href="https://www.nrcs.usda.gov/wps/portal/nrcs/main/national/programs/financial/rcpp/" target="_blank" rel="noreferrer">https://www.nrcs.usda.gov/wps/portal/nrcs/main/national/programs/financial/rcpp/</a></li>
        
        <li><b>Conservation Effects Assessment Project</b> -  Project to quantify the environmental effects of conservation practices and programs on the environment and
          develop the science base for managing the agricultural landscape for environmental quality.
        <a href="https://www.nrcs.usda.gov/wps/portal/nrcs/main/national/technical/nra/ceap/" target="_blank" rel="noreferrer">https://www.nrcs.usda.gov/wps/portal/nrcs/main/national/technical/nra/ceap/</a></li>
        
        <li><b>Cooperative Conservation Partnership</b> -  Initiative to provide financial assistance for partnerships between 
          producers and Indian Tribes, state and local government units, producer associations, farmer cooperatives, 
          institutions of higher education, and NGOs.
        <a href="https://www.nrcs.usda.gov/wps/portal/nrcs/detail/national/programs/financial/rcpp/?cid=stelprdb1242732" target="_blank" rel="noreferrer">https://www.nrcs.usda.gov/wps/portal/nrcs/detail/national/programs/financial/rcpp/?cid=stelprdb1242732</a></li>
      </div>
    </TabPanel>
    <TabPanel className='guidance-panel' value={value} index={4}>
    <h2>Question 5 Guidance</h2>
    <h3><em>This question supports Objective 11 from the Leading Harvest Sustainable Farming Standards. This objective 
      focuses on Legal Compliance.</em></h3>

      <p>Relevant definitions to aid in answering this question are below: </p>
      <div>
        <li><b>AD-1026</b>- Certificate of Compliance, is used to inform the person of the HELC and WC requirements,
        certify compliance with the HELC and WC provisions, identify persons affiliated with the person completing the 
        form, and provide authorization for USDA representatives to service their determination request and spot 
        check compliance with the HELC and WC provisions.</li>

        <li><b>HELC</b> – highly erodible land conservation  </li>

        <li><b>WC</b> – wetland conservation  </li>
      
        <p>If possible, please attach the AD-1026 Form and/or documentation providing evidence that the AD-1026 
          form has been properly filed. Attachments of USDA confirmation of HELC and WC compliance would also be 
          helpful in your answer.
        </p>
        </div>
    </TabPanel>
    <TabPanel className='guidance-panel' value={value} index={5}>
    <h2>Question 6 Guidance</h2>
      <h3><em>This question supports Objectives 5.1 and 5.3 from the Leading Harvest Sustainable Farming Standards.
        These Objectives focus on agricultural energy use and conversation, and climate-smart agriculture.
      </em></h3>
      
      <p>This question has a lot of weight in scoring to determine whether or not the farm will be designated as Eco 
          Elite.</p>
          <div>
      <p>Producers should follow USDA soil erosion guidance on Eco Elite enrolled acres. Please provide as detailed
        a description as possible of the methods you use to maintain soil health and reduce erosion. Another thing you
        could talk about are any future plans of progressing in your development of soil conservation methods if you
        are not currently implementing a lot on the farm. Please attach any documentation of proof that the farm is
        implementing soil conservation methods through the upload file button. Examples of attachments that would work
         include photographic evidence of soil conservation practices.
      </p>
      <table>
        <tr>
          <th>Full Points</th>
          <th>Satisfactory</th>
          <th>Unsatisfactory</th>
        </tr>
        <tr>
          <td>DOT Certified Clean Idle and use of Biodiesel and/or renewable fuel.</td>
          <td>Reducing tillage, more efficient farm operations..</td>
          <td>No practices implemented to reduce fossil fuel use.</td>
        </tr>
      </table>
    </div>
    </TabPanel>
    <TabPanel className='guidance-panel' value={value} index={6}>
    <h2>Question 7 Guidance</h2>
      <h3><em>This question supports Objective 2.1 from the Leading Harvest Sustainable Farming Standards. This objective 
        focuses on Soil Health.</em></h3>
      <div>
      <p>Some examples of maintaining soil fertility and reducing erosion include crop rotation, soil health monitoring, and 
        spreading organic waste. </p>
      
      <p>Crop rotation helps control the erosion of soil from water and wind by improving the soil structure and reducing the
        amount of soil that is exposed to water and wind. It also supports reduced or no-till farming, which ensures even better
        protection against erosion. Monitoring  soil health will provide vital information on the organic-matter content, water 
        infiltration, and root depth. These factors help farmers gauge the health of the soil, which allows them to see if erosion 
        is affecting soil fertility. Spreading organic waste helps restore  the organic content, which allows for greater moisture
        and nutrient retention. It provides necessary food for essential microorganisms that live in healthy soil. In your answer, 
        please mention what methods you use and attach any relevant documentation through the upload files button such as photographic 
        evidence. </p>
    </div>
    <p>The scoring guidelines for Directive 2.1 (which includes Question 9) is as follows:</p>
    <table>
        <tr>
          <th>Full Points</th>
          <th>Satisfactory</th>
          <th>Somewhat Satisfactory</th>
          <th>Unsatisfactory</th>
        </tr>
        <tr>
          <td>Producers are performing tissue sampling and scouting for soil and airborne crop diseases. No tillage and cover crops, crop rotation, use of organic waste instead of commercial fertilizer.</td>
          <td>Producers are performing visual inspections for soil and airborne crop diseases. Implementing crop rotation, and both reduced/no tillage and cover crops.</td>
          <td>Producers are performing visual inspections for soil and airborne crop diseases. Implementing crop rotation, and either reduced/no tillage or cover crops.</td>
          <td>Not working towards increasing Organic Matter or control erosion/runoff of nutrients.</td>
        </tr>
      </table>
    </TabPanel>
    <TabPanel className='guidance-panel' value={value} index={7}>
    <h2>Question 8 Guidance</h2>
      <h3><em>This question supports Objective 2.1 from the Leading Harvest Sustainable Farming Standards. This objective 
      focuses on Soil Health.</em></h3>
      <div>
      <p>Some examples of conservation tillage methods include no till and strip tillage.</p>
      
      <p>Conservation tillage control measures will increase soil health, increase moisture retention,
        reduce soil compaction and soil erosion, reduce water and nutrient runoff, and reduce energy use.
        In your answer, please mention what methods you use and attach any relevant documentation through
        the upload files button such as photographic evidence. For cover cropping, attach a picture and 
        identify what cover crops are used on the property. </p>
        <table>
          <tr>
            <th>Full Points</th>
            <th>Satisfactory</th>
            <th>Unsatisfactory</th>
          </tr>
          <tr>
            <td>No tillage and use of cover crops.</td>
            <td>Some reduced tillage, partial use of cover crops.</td>
            <td>Conventional practices, not using cover crops. </td>
          </tr>
        </table>
      </div>
    </TabPanel>
    <TabPanel className='guidance-panel' value={value} index={8}>
    <h2>Question 9 Guidance</h2>
      <h3><em> This question supports Objectives 2.1 and 2.2 from the Leading Harvest Sustainable Farming Standards. These
      objectives focus on Soil Health and Conservation.</em></h3>
      <div>
      <p>For Soil Health Monitoring, please identify what practices are implemented to monitor the soil and/or 
        what technologies are used for the purpose of soil monitoring. For example, you could attach a file of
        a soil map when answering this question. For Nutrient Management Programs, we would like you to identify
        the ways in which you are monitoring the nutrient content of the soil and would like any appropriate files
        demonstrating that to be uploaded. Another option is to describe any future plans of developing the property's
        management of soil health and nutrient content and explaining how the property plans on developing such a plan.
      </p>
      <p>The scoring guidelines for Directive 2.1 (which includes Question 7) is as follows:</p>
        <table>
        <tr>
          <th>Full Points</th>
          <th>Satisfactory</th>
          <th>Somewhat Satisfactory</th>
          <th>Unsatisfactory</th>
        </tr>
        <tr>
          <td>Producers are performing tissue sampling and scouting for soil and airborne crop diseases. No tillage and cover crops, crop rotation, use of organic waste instead of commercial fertilizer.</td>
          <td>Producers are performing visual inspections for soil and airborne crop diseases. Implementing crop rotation, and both reduced/no tillage and cover crops.</td>
          <td>Producers are performing visual inspections for soil and airborne crop diseases. Implementing crop rotation, and either reduced/no tillage or cover crops.</td>
          <td>Not working towards increasing Organic Matter or control erosion/runoff of nutrients.</td>
        </tr>
       </table>
      </div>
    </TabPanel>
    <TabPanel className='guidance-panel' value={value} index={9}>
    <h2>Question 10 Guidance</h2>
      <h3><em>This question supports Objective 10 from the Leading Harvest Sustainable Farming Standards. This objective 
      focuses on Personnel and Farm Labor. </em> </h3>
      <div>
      <p>The Federal Seed Act requires accurate labeling and purity standards for seeds in commerce, and 
        prohibits the importation and movement of adulterated or misbranded seeds. The Plant Protection Act 
        refers to that the Secretary of the Department of Agriculture may issue regulations to prevent the 
        introduction of plant pests or the dissemination of plant pests. The Coordinatad Framework spells out 
        the basic federal policy for regulating the development and introduction of products derived from 
        biotechnology. Please also provide any other relevant policies in regards to employee training.  </p>
      </div>
      <table>
        <tr>
          <th>Full Points</th>
          <th>Satisfactory</th>
          <th>Unsatisfactory</th>
        </tr>
        <tr>
          <td>In compliance with applicable regulations, employees are given annual training.</td>
          <td>In compliance with applicable regulations, but employees are not trained.</td>
          <td>Not in compliance.</td>
        </tr>
      </table>
    </TabPanel>
    <TabPanel className='guidance-panel' value={value} index={10}>
    <h2>Question 11 Guidance</h2>
      <h3><em>This question supports Objective 6.1 - Management of Waste and Other Materials and 6.2 - Food and Agricultural 
        Waste Resource Recovery</em></h3>
      <div>
      <p>Producers apply measures to reduce and recycle waste and meet all local regulations as related to waste recycling</p>

      <p>All waste should be disposed of properly.  All Manure and organic applications should be in accordance with State
        or EPA regulations. All Pesticides should be disposed of as required by EPA label.  All other
       waste should be disposed of in accordance to Federal, State, and local regulations. </p>
      </div>
    </TabPanel>
    <TabPanel className='guidance-panel' value={value} index={11}>
      <h2>Question 12 Guidance</h2>
      <h3><em>This question supports Objective 6.1 - Management of Waste and Other Materials and 6.2 - Food and Agricultural
        Waste Resource Recovery</em></h3>
      <div>
      <p>Please identify what hazardous waste the farm produces if applicable. If you have any files that document
        hazardous waste production, it would be helpful to include that in your answer. It is of the utmost importance that 
        all hazardous waste is disposed of properly. Disposal of waste should be in accordance with all federal, 
        state, local, and/or EPA regulations as appropriate.</p>
      </div>
      <p>The scoring guidelines for the questions and directive are as follows:</p>
      <table>
        <tr>
          <th>Full Points</th>
          <th>Satisfactory</th>
          <th>Unsatisfactory</th>
        </tr>
        <tr>
          <td>Landowner/Producer is in compliance with local, state and/or federal rules and regulations related to Agricultural chemicals or waste.  In addition, the Landowner/Producer works diligently to recycle as much waste as possible.</td>
          <td>Landowner/Producer is in compliance with local, state and/or federal rules and regulations related to Agricultural chemicals or waste.</td>
          <td>Landowner/Producer does not comply with local, state and/or federal rules and regulations related to Agricultural chemicals or waste.</td>
        </tr>
      </table>
    </TabPanel>
    <TabPanel className='guidance-panel' value={value} index={12}>
    <h2>Question 13 Guidance</h2>
      <h3><em>This question supports Leading Harvest sustainability Objective 3.1 - Water Use and Objective 3.2 - Water Quality</em></h3>
      <div>
      <p>Precision farming uses technology to apply specialized techniques to the needs of individual plots and/or crops.
      It could include the following: variable rate fertilizer and herbicide application, field mapping for
      seed planting and herbicide and pesticide application, field mapping for fertilizer application, 
      grid soil sampling, or yield mapping. </p>
      <li>Variable rate fertilization: Application of different rates or types of fertilizer in unique soil areas 
      across the field.</li>
      <li>Variable rate planting: Application of different seeding rates depending on factors such as soil health, light,
      and water storage capacity.</li>
    </div>
    <table>
      <tr>
        <th>Full Points</th>
        <th>Satisfactory</th>
        <th>Unsatisfactory</th>
      </tr>
      <tr>
        <td>In addition to all Satisfactory requirements: variable rate applications, 
          Precision Farming Techniques, and a process instilled to avoid overuse of watershed. 
          To control water discharge, practices like buffer strips and grass waterways are implemented.</td>
        <td>Manure and Fertilizers are applied in compliance with state regulations, safely, 
          not in excess, and to avoid infiltration and runoff. Water discharge is not increasing.
        </td>
        <td>Runoff is not managed well or controlled, farmers are not using Precisions Techniques
           and are not applying manure in compliance with state regulations (if applicable).</td>
      </tr>
    </table>
    </TabPanel>
    <TabPanel className='guidance-panel' value={value} index={13}>
    <h2>Question 14 Guidance</h2>
      <h3><em>This question supports Leading Harvest sustainability Objective 4.1 - Integrated Pest Management</em></h3>
      <div>
      <li><b>Integrated Pest Management</b> - Process to protect crops against pests while minimizing negative impacts
        to people and the environment.  </li>

      <p>All Applications of Pesticides made by Producer should follow EPA guidelines and recorded. Pesticide
        should be used as labeled on packaging. Pesticide programs should be preventative not reactive. Note that pests broadly do not just include insects/weeds, especially depending on the area the farmer is located.</p>
      </div>
    </TabPanel>
    <TabPanel className='guidance-panel' value={value} index={14}>
    <h2>Question 15 Guidance</h2>
      <h3><em>This question supports Leading Harvest sustainability Objective 4.1 - Integrated Pest Management</em></h3>
      <div>
      <li><b>Integrated Pest Management</b> - Process to protect crops against pests while minimizing negative impacts
      to people and the environment. </li>
      <li>Producers are in compliance with Federal Insecticide, Fungicide and Rodenticide Act (FIFRA) maintaining compliance with agricultural chemical handling, storage, and application regulations. This includes regulations on pesticide safety training, notification of pesticide application, use of personal protective equipment, restricted-entry intervals after pesticide application, decontamination supplies, and emergency medical assistance. (<a href="https://www.epa.gov/laws-regulations/summary-federal-insecticide-fungicide-and-rodenticide-act" target="_blank" rel="noreferrer">https://www.epa.gov/laws-regulations/summary-federal-insecticide-fungicide-and-rodenticide-act</a>)</li>
      <li>Producers do not use chemicals that are banned in accordance with the Rotterdam Convention of the Prior Informed Consent (PIC) Procedure for Certain Hazardous Chemicals and Pesticide in International Trade. (<a href="https://www.state.gov/key-topics-office-of-environmental-quality-and-transboundary-issues/rotterdam-convention-on-the-prior-informed-consent-procedure-for-certain-hazardous-chemicals-and-pesticides-in-international-trade/#:~:text=What%20is%20the%20Rotterdam%20Convention,to%20trade%20in%20hazardous%20chemicals." target="_blank" rel="noreferrer">https://www.state.gov/key-topics-office-of-environmental-quality-and-transboundary-issues/rotterdam-convention-on-the-prior-informed-consent-procedure-for-certain-hazardous-chemicals-and-pesticides-in-international-trade/#:~:text=What%20is%20the%20Rotterdam%20Convention,to%20trade%20in%20hazardous%20chemicals.</a>)</li>
      <li>Producers are compliant with the Toxic Substances Control Act. (<a href="https://www.epa.gov/laws-regulations/summary-toxic-substances-control-act" target="_blank" rel="noreferrer">https://www.epa.gov/laws-regulations/summary-toxic-substances-control-act</a>)</li>

      <p>All Applications of Pesticides made by Producer should follow EPA guidelines and recorded. Pesticide
         should be used as labeled on packaging. Pesticide programs should be preventative not reactive. </p>

      <p>Producers are in compliance with U.S. Environmental Protection Agency (EPA) Worker Protection 
        Standard for Agriculture Pesticides meeting regulations for: pesticide safety training, notification of 
        pesticide application, use of personal protective equipment, restricted-entry intervals after pesticide application,
         decontamination supplies, and emergency medical assistance. An application exclusion zone of 100 feet 
         horizontally from application equipment is required whether the pesticide is applied by air blast application,
          as a spray or fumigant, mist, or fog. Applicators should suspend application if they are aware of any person in
        the application exclusion zone per regulation in Worker Protection Standard by Environmental Protection Agency. </p>
      
      <p>Producers should be willing to share Application information with an Authorzed 3rd party to increase transparency
        in production. Attach any relevant files such as photographs or documents regarding use of treatment options as well as any other relevant files pertaining to how pests are monitored. </p>
      </div>
    </TabPanel>
    <TabPanel className='guidance-panel' value={value} index={15}>
    <h2>Question 16 Guidance</h2>
      <h3><em>This question supports Objectives 8 and 9 from the Leading Harvest Sustainable Farming Standards. These objectives focus on managing farmland on geographically or culturally special sites in a non-destructive manner and making sure local communities are respected and recognized.</em></h3>
      <div>
      <p>A geographically or culturally significant site has historic, aesthetic, scientific, social, or spiritual value for past, present, or future populations. </p>

      <p>The Federal Land Policy Management Act protects public lands for exploitation without authorization or rental agreement. </p>

      <p>Producers shall engage with local communities including traditional land users to ensure that communications of concerns, complaints, or other grievances between community members and producers are understood and addressed in a collaborative manner. </p>
      </div>
    </TabPanel>
    <TabPanel className='guidance-panel' value={value} index={16}>
    <h2>Question 17 Guidance</h2>
      <h3><em>This question supports Leading Harvest sustainability Objective 4.1 - Integrated Pest Management</em></h3>
      <div>
      <li><b>Integrated Pest Management</b> - Process to protect crops against pests while minimizing negative impacts
      to people and the environment. </li>
      <p>Producers are in compliance with U.S. Environmental Protection Agency (EPA) Worker Protection 
        Standard for Agriculture Pesticides meeting regulations for: pesticide safety training, notification of 
        pesticide application, use of personal protective equipment, restricted-entry intervals after pesticide application,
         decontamination supplies, and emergency medical assistance. An application exclusion zone of 100 feet 
         horizontally from application equipment is required whether the pesticide is applied by air blast application,
          as a spray or fumigant, mist, or fog. Applicators should suspend application if they are aware of any person in
        the application exclusion zone per regulation in Worker Protection Standard by Environmental Protection Agency.
      </p>
      <table>
        <tr>
          <th>Full Points</th>
          <th>Satisfactory</th>
          <th>Unsatisfactory</th>
        </tr>
        <tr>
          <td>In compliance with applicable regulations, employees handling the chemicals are given 
            annual training.</td>
          <td>In compliance with applicable regulations, but employees handling the chemicals
             are not trained.</td>
          <td>Not in compliance.</td>
        </tr>
      </table>
      </div>
    </TabPanel>
    <TabPanel className='guidance-panel' value={value} index={17}>
    <h2>Question 18 Guidance</h2>
      <h3><em>This question supports Leading Harvest sustainability Objective 3.2 - Water Quality, Objective 5.2 - Air Quality, and Object 6.1 - Management of Waste and Other materials</em>
      </h3>
      <div>
       <p>Producers must be able to preserve features of the natural environment including air, water and soil. Producers should comply with
          the application of regional agricultural best management practices to protect water quality of groundwater and surface water as well as minimizing airborne dust.
          Producers must manage agricultural chemicals according to regulatory requirements in order to protect the environment. 
       </p>
       <p>The scoring guidelines for this questions and directive, as well as general expectations, are as follows:</p>
       <table>
        <tr>
          <th>Full Points</th>
          <th>Satisfactory</th>
          <th>Unsatisfactory</th>
        </tr>
        <tr>
          <td>Producers are in compliance with all regulations for all storage, preparation and applications of any products on farm.  Producer reduces air emissions/dust through the use of biodiesel, Clean Idle motors, reduced passes in field, and ultimately EV’s. In addition, the producer is minimizing or eliminating any potential for run-off or water quality impacts.</td>
          <td>Producer is in compliance with all regulations related to chemical, fertilizer and fuel storage.  Producer properly handles all mixing activities, refueling, minimizes dust and works to control any water quality issues.</td>
          <td>Producer does not make an effort to properly handle chemicals, waste, and dust. There is no diking of storage facilities, collection of run-off from mixing pads, excess spills in the field, or other practices.</td>
        </tr>
      </table>
       </div>
    </TabPanel>
    <TabPanel className='guidance-panel' value={value} index={18}>
    <h2>Question 19 Guidance</h2>
      <h3><em>This question supports Leading Harvest sustainability Objectives 8 and 9.</em></h3>
      <div>
      <p>Producers shall engage in Best Management Practices which are conservation tools farmers can utilize in order to better protect the environment.
         There are several agricultural Best Management Practices that can be used for the farm. (<a href="https://extension.usu.edu/waterquality/protectyourwater/howtoprotectwaterquality/bmps/index" target="_blank" rel="noreferrer">https://extension.usu.edu/waterquality/protectyourwater/howtoprotectwaterquality/bmps/index</a>)</p>
       <table>
        <tr>
          <th>Full Points</th>
          <th>Satisfactory</th>
          <th>Unsatisfactory</th>
        </tr>
        <tr>
          <td>Actively implementing new ideas and practices on their farm, in addition to the 
            expectations for Satisfactory.</td>
          <td>Working with some outside parties (crop advisors, soil consultants, etc.) and attending 
            outside educational sustainability programs.
          </td>
          <td>No exploration of new management practices.</td>
        </tr>
      </table>
      </div>
    </TabPanel>
    <TabPanel className='guidance-panel' value={value} index={19}>
    <h2>Question 20 Guidance</h2>
      <h3><em>This question broadly supports the Leading Harvest sustainability Objective.</em></h3>
      <div>
      <p>Producers are recommended to share information about the application of substances in their farm. Transparency in farming can beneficial in ensuring
         the quality of produce and the empowerment of consuemrs. 
      </p>
      </div>
    </TabPanel>
    <TabPanel className='guidance-panel' value={value} index={20}>
    <h2>Question 21 Guidance</h2>
      <h3><em>This question supports Objective 11 from the Leading Harvest Sustainable Farming Standards. This objective focuses on applicable federal, state, and local agricultural and any social and environmental laws, statues, and regulations.</em></h3>
      <div>
      <p>Producers shall have documented evidence of land ownership, leases, or other legal agreements to utilize
      land for purpose of Crop production and land owners were compensated with prior and informed consent.
      </p>
      <p>Land use contracts are governed by state statutory and U.S. common law. </p>
      <p>Please note that you are not required to submit a copy of a lease with this form unless you wish to do so. This questions affirms that valid, legal documentation of land ownership exists and is accessible. The person submitting the form affirms that if an audit were requested to affirm ownernship, it is possible to arrange for some way to produce those documents.</p>
    </div>
    </TabPanel>
  </Box>
  </div>
  </>
   );
}

export default Guidance;