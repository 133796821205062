import { React, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import Axios from "axios";
import "./components/Button.css";
import SubmitButton from "./components/SubmitButton";
import "./SignUp.css";
//import UserArray from './components/UserArray';
//import PasswordArray from './components/PasswordArray';

function SignUp() {
  const [firstNameReg, setFirstNameReg] = useState("");
  const [LasttNameReg, setLastNameReg] = useState("");
  const [usernameReg, setUsernameReg] = useState("");
  const [passwordReg, setPasswordReg] = useState("");

  const register = (e) => {
    e.preventDefault();
   Axios.post("https://purdue.halderman.com/SignUp", {
      firstName: firstNameReg,
      lastName: LasttNameReg,
      username: usernameReg,
      password: passwordReg,
    }).then((response) => {
      if (response.ok) {
        alert("User created successfully");
      } else {
        alert("Error while creating user.");
      }
    });
  };

  return (
    <div className="SignUp">
      <form onSubmit={register}>
        <h1>Sign Up</h1>
        <input
          type="text"
          className="signup-input"
          placeholder="First Name"
          onChange={(e) => {
            setFirstNameReg(e.target.value);
          }}
          required
        />
        <input
          type="text"
          className="signup-input"
          placeholder="Last Name"
          onChange={(e) => {
            setLastNameReg(e.target.value);
          }}
          required
        />
        <input
          type="text"
          className="signup-input"
          placeholder="Username"
          onChange={(e) => {
            setUsernameReg(e.target.value);
          }}
          required
        />
        <input
          type="text"
          className="signup-input"
          placeholder="Password"
          onChange={(e) => {
            setPasswordReg(e.target.value);
          }}
          required
        />
        <SubmitButton type="submit" message="Sign Up" />
        <p>
          <Link to="/">Already have an account?</Link>
          <Outlet />
        </p>
      </form>
    </div>
  );
}

export default SignUp;
