import { Outlet, Link } from "react-router-dom";
import React, {useState, useEffect} from 'react';
import './Layout.css';
import logo from "./assets/Halderman-Logo-White.png";

const Layout = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const [button, setButton] = useState(true);
  const showButton = () => {
    if(window.innerWidth <= 960) {
        setButton(false)
    } else {
        setButton(true)
    }
  };

  useEffect(() => {
        showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
        <div>
        <nav className="navbar">
            <div className="navbar-class">
                <img className="image-class" src={logo} alt="Halderman Logo" />
                <div className="menu-icon" onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className='nav-item'>
                        <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                            Login
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/SignUp' className='nav-links' onClick={closeMobileMenu}>
                            Sign Up
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/about' className='nav-links' onClick={closeMobileMenu}>
                            About
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <a href='https://www.halderman.com/contact/'  target="_blank" rel="noopener noreferrer" className='nav-links-mobile' onClick={closeMobileMenu}>
                            Contact
                        </a>
                    </li>
                </ul>
                <Link to='/contact' />
                {button && <a href='https://www.halderman.com/contact/'  target="_blank" rel="noopener noreferrer" className='nav-links-button' onClick={closeMobileMenu}>
                            CONTACT US <i class="fas fa-angle-right" /> </a>}
            </div>
        </nav>
        <Outlet />
        </div>
        );
};

export default Layout;