import "./Login.css";
import { Outlet, Link } from "react-router-dom";
import { React, useState } from "react";
import axios from "axios";
import "./components/Button.css";
import SubmitButton from "./components/SubmitButton";
import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();
  const [usernameLog, setUsernameLog] = useState("");
  const [passwordLog, setPasswordLog] = useState("");

  const login = (event) => {
    event.preventDefault();
    axios
      .post(
        "https://purdue.halderman.com/login",
        {
          username: usernameLog,
          password: passwordLog,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          // FIXME: bad practice to store token in localStorage.
          sessionStorage.setItem("authToken", response.data.authToken);
          alert("user logged in");
          navigate("/FarmManager");
        } else {
          alert("incorrect credentials");
        }
      });
  };

  return (
    <div className="Login">
      <form onSubmit={login}>
      <h1> Login </h1>
        <input
          type="text"
          className="login-input"
          placeholder="Username"
          onChange={(e) => {
            setUsernameLog(e.target.value);
          }}
          required
        />
        <input
          type="password"
          className="login-input"
          placeholder="Password"
          onChange={(e) => {
            setPasswordLog(e.target.value);
          }}
          required
        />
        <SubmitButton type="submit" message="Login" />
        <p>
          <Link to="/SignUp">Need to Sign up?</Link>
          <Outlet />
        </p>
      </form>
    </div>
  );
}

export default Login;
