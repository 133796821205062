import { useState } from 'react';
import FarmForm from './FarmForm';
import './NewFarm.css';

const NewFarm = (props) => {
    const [isEditing, setIsEditing] = useState(false);

    const saveFarmDataHandler = (enteredFarmData) => {
        const farmData = {
            ...enteredFarmData,
            id: Math.random().toString()
        };
        props.onAddFarm(farmData);
        setIsEditing(false);
    };

    const startEditingHandler = () => {
        setIsEditing(true);
    }

    const stopEditingHandler = () => {
        setIsEditing(false);
    }

    return (
        <div className='new-farm'>
            {!isEditing && <button onClick={startEditingHandler}>Add New Farm</button>}
            {isEditing && <FarmForm onSaveFarmData={saveFarmDataHandler} onCancel={stopEditingHandler} />}
        </div>
    );
}

export default NewFarm;