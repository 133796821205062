//import './App.css';
import qList from "./qlist/qlist.json";
import Questions from "./components/Questions";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";

function Form() {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formData = new FormData();
  const [questions, setQuestions] = useState(qList.questions);

  function changeHandler(newQuestions) {
    setQuestions(newQuestions);
  }

  /*
   * This function gets the FileData stored
   * in FileUploadComponenet's state variable
   * and appends it to the formData component
   */

  function getFileData(questionNum, file, filename) {
    questionNum = questionNum.toString()
    console.log(questionNum)
    if (formData.has(questionNum)) {
      formData.set(questionNum, file, filename)
    }
    else {
      formData.append(questionNum, file, filename)
    }
  }

  useEffect(() => {
    axios
      .get("http://purdue.halderman.com/", { crossdomain: true })
      .then((response) => {
        alert(response.data);
      });
  });

  /* This useEffect is run whenever the questions
   * state variable is modified. The function inside it
   * changes the value of the formData each time the questions
   * variable containing the user input changes.
   */

  useEffect(() => {
    var questionsJSON = JSON.stringify(questions)
    if (formData.has("StandardInput")) {
      formData.set("StandardInput", questionsJSON)
    }
    else {
      formData.append("StandardInput", questionsJSON)
    }
    console.log(questionsJSON)
  }, [formData, questions]);

  /*
   * This function is called whenever the user clicks
   * the submit button. This function is passed as a prop
   * to Questions component
   */

  function onSubmitClick() {
    console.log("submit clicked")
    axios.post(
      "http://localhost:5000/form",
      formData, {
        headers : {
          'api-key': 'test',
          "Content-Type": "multipart/form-data"
        }
      }
    ).then((res) => {
        console.log(res)
      }
    )
  }

  return (
    <div className="Form">
      <Questions
        items={questions}
        onChangeQuestions={changeHandler}
        onFileChange={getFileData}
        onSubmitClick={onSubmitClick}
      />
    </div>
  );
}

export default Form;
