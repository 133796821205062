import { useState } from 'react';
import './FarmForm.css';
import Map from '../components/Map';

const FarmForm = (props) => {
    const [enteredName, setEnteredName] = useState('');
    const [enteredLocation, setEnteredLocation] = useState('');
    const [enteredLat, setEnteredLat] = useState('');
    const [enteredLong, setEnteredLong] = useState('');
    const [enteredOwner, setEnteredOwner] = useState('');

    const nameChangeHandler = (event) => {
        setEnteredName(event.target.value);
    }

    const corChangeHandler = (lat, long) => {
        setEnteredLat(lat);
        setEnteredLong(long);
    }

    const addrChangeHandler = (address) => {
        setEnteredLocation(address);
    }

    const ownerChangeHandler = (event) => {
        setEnteredOwner(event.target.value);
    }

    const submitHandler = (event) => {
        event.preventDefault();
        
        const farmData = {
            name: enteredName,
            location: enteredLocation,
            owner: enteredOwner,
            status: "Unsubmitted",
            long: parseFloat(enteredLat).toFixed(3),
            lat: parseFloat(enteredLong).toFixed(3)
        };
        props.onSaveFarmData(farmData);
        setEnteredName('');
        setEnteredLocation('');
        setEnteredOwner('');
        setEnteredLat('');
        setEnteredLong('');
    };

    return (
        <form onSubmit={submitHandler}> 
            <div className='farm-form'>
                <div className='farm-form-div'>
                    <label>Name of Farm</label>
                    <input 
                        type='text' 
                        value={enteredName} 
                        onChange={nameChangeHandler}
                        maxlength="75"
                        required  
                    />
                </div>
                <div className='farm-form-div'>
                    <label>Name of Land Owner</label>
                    <input 
                        type='text' 
                        value={enteredOwner} 
                        onChange={ownerChangeHandler} 
                        maxlength="75"
                        required 
                    />
                </div>
                <div>
                    <Map updateCor={corChangeHandler} updateAddr={addrChangeHandler}/>
                    <div className="info-wrapper">
                        <div className="map-details">Selected Location: <span>{enteredLocation}</span></div>
                    </div>
                </div>
                
            </div>
            <div className='farm-form-final'>
                <button type='button' onClick={props.onCancel}>Cancel</button>
                <button type='submit'>Add Farm</button>
            </div>
        </form>
    );
}

export default FarmForm;