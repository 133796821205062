import React from 'react';
import { useState } from 'react';
import FarmStatus from './FarmStatus';
import './FarmList.css';
import { Link } from "react-router-dom";

const FarmList = (props) => {
    const [status, setStatus] = useState("All");

    const selectStatusHandler = (event) => {
        setStatus(event.target.value);
    };

    const filteredFarms = props.items.filter(farm => {
        return (farm.status === status) || (status === "All");
    });

    return (
        <div>
            <div className='top-wrapper'>
            <FarmStatus farms={filteredFarms} />
            <div className='farm-filter'>
                <label>Filter by submission status:</label>
                <select value={status} onChange={selectStatusHandler}>
                     <option value="All">Show All Farms</option>
                     <option value="Unsubmitted">Show Unsubmitted Farms</option>
                     <option value="Submitted">Show Submitted Farms</option>
                </select>
            </div>
            </div>
            <div className='bottom-wrapper'>
            {filteredFarms.length !== 0 && <ul className='expenses-list'>
            {filteredFarms.map(
                farm => <div className="farm-item">
                            <div className="farm-description">
                                <h2><b>Name:</b> {farm.name} </h2>
                                <h2><b>Owner:</b> {farm.owner} </h2>
                                <div className='loc-div'><h2><b>Coordinates:</b></h2> <p>({farm.lat}, {farm.long})</p></div>
                                <div className='loc-div'><h2><b>Location:</b></h2> <p>{farm.location}</p></div>
                            </div>
                            <div className='farm-status'>
                                <h2><b>Status:</b> {farm.status}</h2>
                                <div className='farm-buttons'>
                                  {farm.status === "Unsubmitted" && <button type="text" className="button">   
                                  <Link to={`/Form`} className="link-q">
                                    Submit Form
                                  </Link>                             
                                  </button>} 
                                  {farm.status === "Submitted" && <button type="text" className="button">  
                                   <Link to={`/Form`} className="link-q">
                                    Submit Updates
                                  </Link>
                                  </button>}      
                                  <button className="button" onClick={function() { props.onDeleteFarm(farm); }}>Delete</button>
                                </div>                        
                            </div>
                        </div>)}                 
                        </ul>}
            {filteredFarms.length === 0 && <h2>Found no farms.</h2>}
            </div>
        </div>
    );
}

export default FarmList;