import React, { useState, useEffect } from 'react';
import NewFarm from './FarmPage/NewFarm';
import FarmList from './FarmPage/FarmList';
import './FarmManager.css'
import axios from 'axios';

export const INIT_FARMS = [
  {
    id: 0,
    name: "test-farm-one",
    owner: "halderman-1",
    location: "indiana",
    status: "Submitted",
    long: "",
    lat: ""
  }, {
    id: 1,
    name: "test-farm-two",
    owner: "halderman-2",
    location: "california",
    status: "Submitted",
    long: "",
    lat: ""
  }, {
    id: 2,
    name: "test-farm-three",
    owner: "halderman-3",
    location: "washington",
    status: "Unsubmitted",
    long: "",
    lat: ""
  },
  ];

const FarmManager = (props) => {

  const [farms, setFarms] = useState(INIT_FARMS);

  const getFarms = async () => {
    const { data } = await axios.get(`https://purdue.halderman.com/farm/${sessionStorage.getItem("authToken")}`)
    if (!data) alert("error getting farm data")
    else {
      for (var farmObj of data) {
        // eslint-disable-next-line no-loop-func
        setFarms((prevState) => {
          return [...prevState, farmObj]
        })
      }
    }
  }
  
  useEffect(() => {
    getFarms()
  }, [])


  const addFarmHandler = (farm) => {
    setFarms((prevFarms) => {
      return [farm, ...prevFarms]
    });
  };

  const removeFarmHandler = (farm) => {
    setFarms((prevFarms) => {
      let isCanceled = false;
      if (farm === null) {
        isCanceled = true;
        return [...prevFarms];
      }
      if (!isCanceled) {
        if (window.confirm("Are you sure you want to delete this farm? Form data will be lost.")) {
          let i;
          for (i = 0; i < prevFarms.length; i++) {
            if (prevFarms[i].id === farm.id && prevFarms[i].name === farm.name && 
              prevFarms[i].owner === farm.owner) {
              prevFarms.splice(i,1);
              break;
            }
          }
          return [...prevFarms]
        } else {
          return [...prevFarms];
        }
      }
    });
  }
 
    return (
      <div className='manager-wrapper'>
        <NewFarm onAddFarm={addFarmHandler} />
        <FarmList items={farms} onDeleteFarm={removeFarmHandler} />
      </div>
    );
};

export default FarmManager;
